var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h2',{staticClass:"titulo left"},[_vm._v("Datos Alumni Internacional")]),_c('div',{staticClass:"contenedor-input row-start fww"},[_c('h5',[_vm._v("¿Cuál es tu relación con la UFV?")]),_vm._l((_vm.dominios.relacionufv),function(n,key){return _c('div',{key:key,staticClass:"full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.international_relation),expression:"data.international_relation"}],attrs:{"type":"radio","name":"international_relation","id":'relacion-'+key},domProps:{"value":key,"checked":_vm._q(_vm.data.international_relation,key)},on:{"change":function($event){return _vm.$set(_vm.data, "international_relation", key)}}}),_c('label',{attrs:{"for":'relacion-'+key}},[_vm._v(_vm._s(n))])])})],2),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('year-datepicker',{class:classes,attrs:{"label":'¿Cuándo participaste o hiciste tu intercambio con la UFV?',"minpage":new Date(),"name":'international_year'},model:{value:(_vm.data.international_year),callback:function ($$v) {_vm.$set(_vm.data, "international_year", $$v)},expression:"data.international_year"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.internaltional_buddy),expression:"data.internaltional_buddy"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"buddy","placeholder":"Indícanos el nombre de tu buddy en la UFV"},domProps:{"value":(_vm.data.internaltional_buddy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "internaltional_buddy", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul"},[_vm._v("Siguiente")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }