var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"microform",attrs:{"edit":_vm.edit}},[(!_vm.edit && _vm.data)?[_c('h3',[_vm._v(_vm._s(_vm.dominios.nivel[_vm.data.level]))]),_c('h2',[_vm._v(_vm._s(_vm.data.study.name)+", "+_vm._s(_vm.data.university.name)+", "+_vm._s(_vm.data.year))]),(_vm.data.elu_year)?_c('p',[_vm._v("Promoción ELU: "+_vm._s(_vm.data.elu_year)+" ")]):_vm._e()]:_vm._e(),(_vm.data.dataId)?_c('a',{staticClass:"editar",on:{"click":function($event){_vm.edit = true}}},[_vm._v("editar")]):_vm._e(),(_vm.data.dataId)?_c('a',{staticClass:"eliminar",on:{"click":function($event){_vm.showerase = true}}},[_vm._v("Eliminar")]):_vm._e(),(_vm.edit)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[(!_vm.notelu)?[(!_vm.elu)?_c('div',{staticClass:"contenedor-input row-start only-one-option"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.haselu),expression:"data.haselu"}],attrs:{"type":"checkbox","name":"haselu","id":'haselu-'+_vm.pos},domProps:{"value":true,"checked":Array.isArray(_vm.data.haselu)?_vm._i(_vm.data.haselu,true)>-1:(_vm.data.haselu)},on:{"change":function($event){var $$a=_vm.data.haselu,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data, "haselu", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data, "haselu", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data, "haselu", $$c)}}}}),_c('label',{class:classes,attrs:{"for":'haselu-'+_vm.pos}},[_vm._v("Soy Egresado de la Escuela de Liderazgo Universitario (ELU)")]),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.data.haselu)?_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('year-datepicker',{class:classes,attrs:{"label":'Año de promoción ELU',"minpage":new Date(),"name":'elu_year'},model:{value:(_vm.data.elu_year),callback:function ($$v) {_vm.$set(_vm.data, "elu_year", $$v)},expression:"data.elu_year"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.data.haselu)?_c('figure',[_vm._v(" Selecciona estudios de acceso a ELU ")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{attrs:{"for":""}},[_vm._v("Nivel")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.level),expression:"data.level"}],staticClass:"pretty",class:classes,attrs:{"id":"nivel","name":"nivel"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "level", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"name":"myBrowser","placeholder":"","value":""}}),_vm._l((_vm.dominios.nivel),function(nivel,key){return _c('option',{key:key,attrs:{"name":"myBrowser"},domProps:{"value":key}},[_vm._v(" "+_vm._s(nivel))])})],2),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('autocomplete',{class:classes,attrs:{"name":'study',"label":'Titulación',"type":'study',"idkey":'id'},model:{value:(_vm.data.study),callback:function ($$v) {_vm.$set(_vm.data, "study", $$v)},expression:"data.study"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('autocomplete',{ref:"university",class:classes,attrs:{"name":'university',"label":'Universidad',"type":(!_vm.ownonlyufv) ? 'university' : 'ufv',"idkey":'id'},model:{value:(_vm.data.university),callback:function ($$v) {_vm.$set(_vm.data, "university", $$v)},expression:"data.university"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('year-datepicker',{class:classes,attrs:{"label":'Año de promoción',"minpage":new Date(),"name":'year'},model:{value:(_vm.data.year),callback:function ($$v) {_vm.$set(_vm.data, "year", $$v)},expression:"data.year"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Guardar")])])],2)]}}],null,false,2754427596)})]:_vm._e(),(_vm.showerase)?_c('popup-confirmacion',{attrs:{"loading":_vm.loadingerase,"how":'borraracademico',"isnegative":true},on:{"accept":_vm.requestErase,"cancel":function($event){_vm.showerase = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }